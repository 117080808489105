import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

function schemaGenerator(data) {
  const schemaObj = {};
  schemaObj[`@context`] = 'https://schema.org';
  schemaObj[`@type`] = 'NewsArticle';
  schemaObj['name'] = `${data?.name}`;
  schemaObj['url'] = data?.url;
  schemaObj['headline'] = `${data?.headline}`;
  schemaObj['mainEntityOfPage'] = data?.mainEntityOfPage;
  return schemaObj;
}
function NewsArticle({ data }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaGenerator(data)),
        }}
      />
    </Head>
  );
}

NewsArticle.propTypes = {
  name: PropTypes.string,
  mainEntityOfPage: PropTypes.string,
  url: PropTypes.string,
  headline: PropTypes.string,
};

export default NewsArticle;
