/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

function schemaGenerator(data) {
  const schemaObj = {};
  schemaObj[`@context`] = 'https://schema.org';
  schemaObj[`@type`] = 'Table';
  schemaObj['about'] = `${data}`;
  return schemaObj;
}
function TableSchema({ data }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaGenerator(data)),
        }}
      />
    </Head>
  );
}

TableSchema.propTypes = {
  about: PropTypes.string,
};

export default TableSchema;
