import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { getOriginByEnv } from 'core-lib/config/config';

function schemaGenerator(data) {
  const schemaObj = {};
  schemaObj[`@context`] = 'https://schema.org';
  schemaObj[`@type`] = 'SiteNavigationElement';
  schemaObj['name'] = `${data?.name}`;
  schemaObj['url'] = `${getOriginByEnv()}${data?.url}`;
  return schemaObj;
}
function SiteNavigationElement({ data }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaGenerator(data)),
        }}
      />
    </Head>
  );
}

SiteNavigationElement.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
};

export default SiteNavigationElement;
