import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { redirectToPWA } from 'src/utils/isAuthAndRedirect';

import s from './navbar.module.scss';

const NavBar = memo((props) => {
  const router = useRouter();
  const { navList } = props;

  const handleClick = (link) => {
    if (link?.includes('fastag')) {
      router.push(link);
    } else {
      redirectToPWA(link);
    }
  };

  return (
    <div className={s.cmn_navbar}>
      {navList.map((item) => {
        const isActive = item.route.indexOf(router.pathname) !== -1;
        return (
          <div
            key={`tab-${item.key}`}
            className={classNames(s.nav_tab, {
              active_tab: isActive,
            })}
          >
            <button onClick={() => handleClick(item?.href)}>
              <div className={s.icon}>
                {/* {isActive ? item.activeIcon : item.icon} */}
                {isActive ? (
                  <img src={item.activeIcon} alt={`Active ${item.name}`} />
                ) : (
                  <img src={item.icon} alt={`Not active ${item.name}`} />
                )}
              </div>
              <span className={s.label}>{item.name}</span>
            </button>
          </div>
        );
      })}
    </div>
  );
});

NavBar.propTypes = {
  navList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

NavBar.defaultProps = {};

export default NavBar;
