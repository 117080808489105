import React from 'react';
import Head from 'next/head';
import PropTypes, { any } from 'prop-types';

function schemaGenerator(data) {
  const schemaObj = {};
  schemaObj[`@context`] = 'https://schema.org';
  schemaObj[`@type`] = 'ParkingFacility';
  schemaObj['address'] = {
    '@type': 'PostalAddress',
    addressLocality: `${data?.address?.addressLocality}`,
    streetAddress: `${data?.address?.streetAddress}`,
  };
  schemaObj['latitude'] = `${data?.latitude}`;
  schemaObj['longitude'] = `${data?.longitude}`;

  return schemaObj;
}
function ParkingFacility({ data }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaGenerator(data)),
        }}
      />
    </Head>
  );
}
ParkingFacility.propTypes = {
  address: {
    addressLocality: PropTypes.string,
    streetAddress: PropTypes.string,
  },
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};

export default ParkingFacility;
