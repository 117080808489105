import React from 'react';

import PropTypes from 'prop-types';
import Link from 'next/link';
import Head from 'next/head';
import { getOriginByEnv } from 'core-lib/config/config';
import s from './bread_crumbs.module.scss';

const LINK_TYPE = {
  internal: 'internal',
  external: 'external',
};

function schemaGenerator(data) {
  const schemaObj = {};
  schemaObj[`@context`] = 'https://schema.org';
  schemaObj[`@type`] = 'BreadcrumbList';
  schemaObj.itemListElement = [];
  data?.map((item, idx) => {
    const listItem = {};
    listItem[`@type`] = 'ListItem';
    listItem.name = item?.name;
    listItem.position = idx + 1;
    listItem.item = `${getOriginByEnv()}${item?.url}`;
    return schemaObj.itemListElement.push(listItem);
  });
  return schemaObj;
}
function BreadCrumb({ data }) {
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaGenerator(data)),
          }}
        />
      </Head>
      <div className={['cmn_container', s.root].join(' ')}>
        {data?.map((item, index) => (
          <div key={item?.id} className={s.link_item}>
            {item?.type === 'internal' ? (
              <>
                <Link href={item.url}>
                  {item?.name}
                </Link>
                {index < data.length - 1 && <> &#x0003E; </>}
              </>
            ) : (
              <>
                <a href={`${getOriginByEnv()}${item?.url}`}>{item?.name}</a>
                {index < data.length - 1 && <> &#x0003E; </>}
              </>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

BreadCrumb.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      type: PropTypes.oneOf([LINK_TYPE.internal, LINK_TYPE.external]),
    })
  ).isRequired,
};

export default BreadCrumb;
