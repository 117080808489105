import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

function schemaGenerator(data) {
  const schemaObj = {};
  schemaObj[`@context`] = 'https://schema.org';
  schemaObj[`@type`] = 'FinancialProduct';
  schemaObj['name'] = data?.name;
  schemaObj['url'] = data?.url;
  schemaObj['brand'] = data?.brand;
  schemaObj['category'] = data.category;
  schemaObj['offers'] = data?.offers;
  return schemaObj;
}
function FinancialProductSchema({ data }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaGenerator(data)),
        }}
      />
    </Head>
  );
}

FinancialProductSchema.propTypes = {
  data: {
    name: PropTypes.string,
    url: PropTypes.string,
    brand: PropTypes.string,
    category: PropTypes.string,
    offers: PropTypes.object,
  },
};

export default FinancialProductSchema;
