import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

function schemaGenerator() {
  const schemaObj = {};
  schemaObj[`@context`] = 'https://schema.org';
  schemaObj[`@type`] = 'MobileApplication';
  schemaObj['name'] = 'Park+';
  schemaObj['operatingSystem'] = 'ANDROID and iOS';
  schemaObj['offers'] = {
    '@type': 'Offer',
    price: '0',
    priceCurrency: 'INR',
  };
  schemaObj['aggregateRating'] = {
    '@type': 'AggregateRating',
    ratingValue: '4.6',
    ratingCount: '71140',
  };
  schemaObj['applicationCategory'] = 'Apps';
  schemaObj['potentialAction'] = {
    '@type': 'ViewAction',
    target: [
      {
        '@type': 'EntryPoint',
        actionApplication: {
          '@type': 'SoftwareApplication',
          '@id': 'com.ovunque.parkwheels',
          name: 'Park+ Parking | FASTag | RTO',
          operatingSystem: 'ANDROID',
          applicationCategory: 'Apps',
          applicationSubCategory: 'Auto & Vehicles',
          downloadUrl:
            'https://play.google.com/store/apps/details?id:com.ovunque.parkwheels',
          offers: {
            '@type': 'Offer',
            price: '0',
            priceCurrency: 'INR',
          },
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '4.6',
            ratingCount: '71140',
          },
        },
      },
      {
        '@type': 'EntryPoint',
        actionApplication: {
          '@type': 'SoftwareApplication',
          '@id': '1244749178',
          name: 'Park+ | Find Parking, Challan',
          operatingSystem: 'iOS',
          applicationCategory: 'Travel',
          downloadUrl:
            'https://apps.apple.com/in/app/park-find-parking-challan/id1244749178',
          offers: {
            '@type': 'Offer',
            price: '0',
            priceCurrency: 'INR',
          },
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '4.0',
            ratingCount: '737',
          },
        },
      },
    ],
  };
  schemaObj['description'] =
    'Park+ app is a one-stop solution for car owners. What you know was the best FASTag app offers so much more. From finding and booking parking spots near you and selling your car to getting your car cleaned daily right at your doorstep.';
  return schemaObj;
}

function mobileApplicationSchema() {
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaGenerator()),
          }}
        />
      </Head>
    </>
  );
}

mobileApplicationSchema.propTypes = {
  name: PropTypes.string,
  offers: PropTypes.object,
  aggregateRating: PropTypes.object,
  applicationCategory: PropTypes.string,
  potentialAction: PropTypes.object,
  description: PropTypes.string,
};

export default mobileApplicationSchema;
