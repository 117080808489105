import { getOriginByEnv } from 'core-lib/config/config';
import Head from 'next/head';
import Link from 'next/link';
import classNames from 'classnames';
import s from './bread_crumbs_v2.module.scss';

type LinkType = 'internal' | 'external';

interface BreadcrumbItem {
  id: string;
  name: string;
  url: string;
  type: LinkType;
}

interface BreadCrumbV2Props {
  data: BreadcrumbItem[];
}

const LINK_TYPE = {
  internal: 'internal' as LinkType,
  external: 'external' as LinkType,
};

function schemaGenerator(data: BreadcrumbItem[]) {
  const schemaObj: any = {};
  schemaObj[`@context`] = 'https://schema.org';
  schemaObj[`@type`] = 'BreadcrumbList';
  schemaObj.itemListElement = [];
  data?.map((item, idx) => {
    const listItem: any = {};
    listItem[`@type`] = 'ListItem';
    listItem.name = item?.name;
    listItem.position = idx + 1;
    listItem.item = `${getOriginByEnv()}${item?.url}`;
    return schemaObj.itemListElement.push(listItem);
  });
  return schemaObj;
}

function BreadCrumbV2({ data }: BreadCrumbV2Props) {
  return (
    <div className={s.topRoot}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaGenerator(data)),
          }}
        />
      </Head>
      <div className={classNames(s.root, 'cmn_container')}>
        {data?.map((item, index) => (
          <div key={item?.id} className={s.link_item}>
            {item?.type === 'internal' ? (
              <>
                <Link href={item.url}>{item?.name}</Link>
                {index < data.length - 1 && <> &#x0003E; </>}
              </>
            ) : (
              <>
                <a href={`${getOriginByEnv()}${item?.url}`}>{item?.name}</a>
                {index < data.length - 1 && <> &#x0003E; </>}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default BreadCrumbV2;
