import Head from 'next/head';

function schemaGenerator(data) {
  const schemaObj = {
    '@context': 'https://schema.org',
    '@type': 'Vehicle',
    brand: data?.brand,
    name: data?.name,
    description: data?.description,
    image: data?.image,
    bodyType: data?.bodyType,
    ...(data?.modelDate) && { modelDate: data?.modelDate },
    fuelCapacity: data?.fuelCapacity,
    numberOfAirBags: data?.numberOfAirBags,
    vehicleTransmission: data?.vehicleTransmission,
    numberOfDoors: data?.numberOfDoors,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: data?.aggregateRating?.ratingValue,
      reviewCount: data?.aggregateRating?.reviewCount,
    },
    ...(data?.topReviews) && {
    review: [
        data?.topReviews?.map((post) => {
          return {
            '@type': 'Review',
            author:{
              '@type': 'Person',
              name: post?.name,
            },
            datePublished: post?.reviewDate,
            description: post?.description,
            name: post?.title,
            reviewRating:{
              '@type': 'Rating',
              ratingValue: post?.totalRating,
            }
          };
        }),
      ]
    },
    vehicleEngine:{
      '@type': 'EngineSpecification',
      fuelType: data?.vehicleEngine?.fuelType,
      engineType: data?.vehicleEngine?.engineType,
      engineDisplacement: data?.vehicleEngine?.engineDisplacement,
      torque: {
        '@type': 'QuantitativeValue',
        value: data?.vehicleEngine?.torque,
      },
      enginePower: {
        '@type': 'QuantitativeValue',
        value: data?.vehicleEngine?.enginePower,
      }
    },
    driveWheelConfiguration: data?.driveWheelConfiguration,
    mileageFromOdometer: {
      '@type': 'QuantitativeValue',
      value: data?.mileageFromOdometer?.value,
      unitText: 'Combined'
    },
    accelerationTime: {
      '@type': 'QuantitativeValue',
      value: data?.accelerationTime?.value,
      unitText: data?.accelerationTime?.unitText,
    },
    weightTotal: {
      '@type': 'QuantitativeValue',
      value: data?.weightTotal?.value,
      unitText: data?.weightTotal?.unitText,
    },
    vehicleInteriorColor: data?.vehicleInteriorColor,
    seatingCapacity: {
      '@type': 'QuantitativeValue',
      value: data?.seatingCapacity,
    },
    color: data?.color,
    manufacturer: {
      '@type': 'Organization',
      name: data?.manufacturer?.name,
      url: data?.manufacturer?.url
    },
    offers: {
      '@type': 'Offer',
      priceCurrency: data?.offers?.priceCurrency,
      price: data?.offers?.price,
      availability: 'http://schema.org/InStock',
      priceValidUntil: '2024-12-31'
    }
  };
   return schemaObj;
}
function VehicleSchema({ data }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaGenerator(data)),
        }}
      />
    </Head>
  );
}

export default VehicleSchema;
