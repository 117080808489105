import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

function schemaGenerator(data) {
  const schemaObj = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: data?.name,
    url: `https://parkplus.io/author/${data?.slug}`,
    image: data?.image,
    sameAs: [
      data?.facebookUrl,
      data?.linkedinUrl,
      data?.twitterUrl,
      data?.instagramUrl,
    ],
    description: data?.description,
    jobTitle: data?.designation,
    publishingPrinciples: [
      data?.publishingArticles?.map((article) => {
        return {
          '@type': 'Article',
          name: article?.attributes?.seoTitle,
          url: article?.attributes?.seoCanonicalUrl,
          datePublished:  article?.attributes?.createdAt,
          dateModified: article?.attributes?.updatedAt,
        };
      }),
    ],
  };
  return schemaObj;
}
function AuthorSchema({ data }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaGenerator(data)),
        }}
      />
    </Head>
  );
}

// AuthorSchema.propTypes = {
//   name: PropTypes.string,
//   mainEntityOfPage: PropTypes.string,
//   url: PropTypes.string,
//   headline: PropTypes.string,
// };

export default AuthorSchema;
