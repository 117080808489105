import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

function schemaGenerator(data) {
  const schemaObj = {
    '@context': 'https://schema.org',
    '@type': data?.mainType,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': data?.category? `https://parkplus.io/blog/${data?.category}/${data?.slug}`: `https://parkplus.io/car-news/${data?.slug}`,
    },
    headline: data?.headline,
    image: {
      '@type': 'ImageObject',
      url: data?.backgroundImage,
      height: 600,
      width: 1200,
    },
    datePublished: data?.createdAt,
    dateModified: data?.updatedAt,
    author: {
      '@type': 'Person',
      name: data?.author?.name,
      sameAs: `https://parkplus.io/author/${data?.author?.slug}`,
    },
      publisher: {
        '@type': 'Organization',
        name: 'Park+',
        logo: {
          '@type': 'ImageObject',
          url: 'https://parkplus.io/favicon.ico',
          height: 60,
          width: 60,
        },
        url: 'https://https://parkplus.io/',
      },
    description: data?.description,
    articleBody: data?.articleBody,
    genre: data?.genre,
    wordCount: 1500,
  };
  return schemaObj;
}

function BlogArticle({ data }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaGenerator(data)),
        }}
      />
    </Head>
  );
}

BlogArticle.propTypes = {
  name: PropTypes.string,
  mainEntityOfPage: PropTypes.string,
  url: PropTypes.string,
  headline: PropTypes.string,
};

export default BlogArticle;
