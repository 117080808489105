import Head from 'next/head';
``
function schemaGenerator(data) {
    const schemaObj = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: data?.itemList?.map((item, index) => {
            return {
                '@type': 'ListItem',
                position: item?.position,
                url: item?.url
            }
        })
    }
  return schemaObj;
}
function ItemListSchemaNew({ data }) {
    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(schemaGenerator(data)),
                }}
            />
        </Head>
    );
}

export default ItemListSchemaNew;
