import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import s from './Subheader.module.scss';

export type TabItemTypes = {
  id: string | number;
  label: string;
  route: string;
  routeList: string[];
};

type TabsProps = {
  tabs: TabItemTypes[];
};

export const Subheader = ({ tabs }: TabsProps) => {
  const router = useRouter();
  const { asPath, pathname } = router;
  return (
    <div className={s.root}>
      <div className={cn(s.tabs, 'cmn_container', 'cmn_hide_scrollbar')}>
        {tabs.map((item) => (
          <Link href={item.route} key={item.id} prefetch={false} className={cn(s.links, {
            [s.active_link]: item.routeList.includes(asPath),
          })}>
              {item.label}
          </Link>
        ))}
      </div>
    </div>
  );
};
